<template>
  <div class="personal_tab_pane_content_pane3">
    <div class="personal_tab_pane_content_pane3_item" v-for="(item,index) in vip" :key="index">
      <p>VIP会员</p>
      <el-divider>{{item.type}}</el-divider>
      <p>专属活动</p>
      <p>专属教练</p>
      <p>{{item.gift}}</p>
      <el-button><span>开</span><span>通</span></el-button>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      vip:[
        {
          type:'7天体验卡',
          gift:''
        },
        {
          type:'30天月卡',
          gift:''
        },
        {
          type:'年卡',
          gift:'送小聂跑鞋一双'
        },
        {
          type:'APP终身卡',
          gift:'送小聂背心一件'
        },
        {
          type:'终身学员卡',
          gift:'跑鞋+战队服装'
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.personal_tab_pane_content_pane3{
  text-align: left;
  .personal_tab_pane_content_pane3_item{
    display: inline-block;
    width: 30%;
    margin: 40px 0;
    margin-right: calc(5% - 3px);
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border: 1px solid #ebeef5;
    &:nth-of-type(3n){
      margin: 0;
    }
    p{
      text-align: center;
      background-color: #F8BCAC;
      color: #000;
      font-weight: bold;
      padding: 10px 0;
      &:first-child{
        letter-spacing: 10px;
        font-size: 32px;
        font-weight: bolder;
        background-color: #fff;
        color: #E85D04;
      }
      &:nth-of-type(4){
        background-color: #fff;
        font-size: 14px;
        color: #888;
        letter-spacing: 5px;
        height: 39px;
        box-sizing: border-box;
      }
    }
    .el-divider{
      background-color: #E85D04;
      margin-bottom: 20px;
      margin: 40px 0;
      .el-divider__text{
        color: #E85D04;
        font-weight: bold;
        letter-spacing: 5px;
      }
    }
    .el-button{
      background-color: #E85D04;
      color: #fff;
      width: 60%;
      margin: 0 auto;
      display: block;
      border-radius: 22px;
      font-weight: bold;
      font-size: 18px;
      padding: 12px 50px;
      margin-bottom: 40px;
      span{
        &:first-child{
          float: left;
        }
        &:last-child{
          float: right;
        }
      }
    }
  }
}

@media screen and (max-width:1200px){
  .personal_tab_pane_content_pane3{
    text-align: left;
    .personal_tab_pane_content_pane3_item{
      display: inline-block;
      width: 100%;
      margin: 3vh 0;
      // margin-right: calc(5% - 3px);
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      border: 1px solid #ebeef5;
      &:nth-of-type(3n){
        margin: 0;
      }
      p{
        text-align: center;
        background-color: #F8BCAC;
        color: #000;
        font-weight: bold;
        padding: 10px 0;
        &:first-child{
          letter-spacing: 10px;
          font-size: 32px;
          font-weight: bolder;
          background-color: #fff;
          color: #E85D04;
        }
        &:nth-of-type(4){
          background-color: #fff;
          font-size: 14px;
          color: #888;
          letter-spacing: 5px;
          height: 39px;
          box-sizing: border-box;
        }
      }
      .el-divider{
        background-color: #E85D04;
        margin-bottom: 20px;
        margin: 40px 0;
        .el-divider__text{
          color: #E85D04;
          font-weight: bold;
          letter-spacing: 5px;
        }
      }
      .el-button{
        background-color: #E85D04;
        color: #fff;
        width: 60%;
        margin: 0 auto;
        display: block;
        border-radius: 22px;
        font-weight: bold;
        font-size: 18px;
        padding: 12px 50px;
        margin-bottom: 40px;
        span{
          &:first-child{
            float: left;
          }
          &:last-child{
            float: right;
          }
        }
      }
    }
  }
}
</style>
